<template>
  <div class="question-template-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="模版名称" prop="title">
              <el-input v-model="searchFrom.title" :clearable="true" placeholder="请输入模版名称"></el-input>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                type="date"
                clearable
                value-format="yyyy-MM-dd 00:00:00"
                placeholder="请选择开始时间"
                :picker-options="pickerOptionsStart(searchFrom.endTime)"
                v-model="searchFrom.startTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="失效时间" prop="endTime">
              <el-date-picker
                type="date"
                clearable
                value-format="yyyy-MM-dd 23:59:59"
                placeholder="请选择失效时间"
                :picker-options="pickerOptionsEnd(searchFrom.startTime)"
                v-model="searchFrom.endTime"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select clearable v-model="searchFrom.status" placeholder="请选择状态">
                <el-option label="全部" value="" />
                <el-option label="有效" value="0" />
                <el-option label="失效" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-search"
                type="primary"
                :disabled="disabledSearch"
                :loading="disabledSearch"
                @click="searchResule"
              >查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card class="card" shadow="always">
      <div class="table-block">
        <el-table
          :data="tableData"
          ref="multipleTable"
          :loading="loading"
          :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#999' }"
          :cell-style="{ 'text-align': 'center' }"
          style="width: 100%"
        >
          <el-table-column prop="title" label="模板名称"></el-table-column>
          <el-table-column prop="questionType" label="模板类型">
            <template slot-scope="scope">
              {{ scope.row.questionType === "1" ? "申请单" : "满意度调查" }}
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间"></el-table-column>
          <el-table-column prop="endTime" label="失效时间"></el-table-column>
          <el-table-column prop="createBy" label="添加人"></el-table-column>
          <el-table-column prop="statusStr" label="状态"></el-table-column>
          <el-table-column label="操作" fixed="right" width="220">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="reviewTemplate(scope.row)" type="text" size="small">查看模板</el-button>
              <el-button @click="downloadFn(scope.row, false)" type="text" size="small">
                下载
              </el-button>
              <el-button @click="getQRcode(scope.row)" type="text" size="small">下载二维码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 40]"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="searchFrom.pageNo"
        :page-size="searchFrom.pageSize"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="修改模板时间"
      :visible.sync="dialogVisible"
      width="720px"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-card>
        <el-form ref="editFrom" :model="editFrom" :rules="rules" label-width="80px" :inline="true">
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker
              type="date"
              clearable
              value-format="yyyy-MM-dd 00:00:00"
              placeholder="请选择开始时间"
              :picker-options="pickerOptionsStart(editFrom.endTime)"
              v-model="editFrom.startTime"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="失效时间" prop="endTime">
            <el-date-picker
              type="date"
              clearable
              value-format="yyyy-MM-dd 23:59:59"
              placeholder="请选择失效时间"
              :picker-options="pickerOptionsEnd(editFrom.startTime)"
              v-model="editFrom.endTime"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-card>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureCommit" :loading="btnLoading">确 定</el-button>
      </span>
    </el-dialog>
    <applicationForm ref="applicationForm" />
    <el-dialog :visible.sync="dialogProps.visible" width="50%" :title="dialogProps.title" :close-on-click-modal="false"
               @close="closeDialog">
      <questionSurveyDetail v-if="dialogProps.visible" :factionType="dialogProps.factionType" />
    </el-dialog>
  </div>
</template>
<script>
import { createQrCode, download, queryQuestionList, updateQuestion } from "@/api/modules/questionTemplate";
import applicationForm from "@/views/questionTemplate/applicationForm.vue";
import questionSurveyDetail from "@/views/questionsurvey/components/questionSurveyDetail.vue";
import { Loading } from "element-ui";
import { postRequestDown } from "@/api";

export default {
  components: { questionSurveyDetail, applicationForm },
  data() {
    return {
      searchFrom: {
        title: "",
        startTime: "",
        endTime: "",
        status: "",
        pageNo: 1,
        pageSize: 10
      },
      editFrom: {
        endTime: "",
        id: "",
        startTime: ""
      },
      dialogVisible: false,
      btnLoading: false,
      tableData: [],
      total: null,
      loading: true,
      disabledSearch: false,
      dialogProps: {
        visible: false,
        params: {},
        title: "满意度调查详情"
      },
      rules: {
        startTime: [{ required: true, message: "请选择开始时间", trigger: "change" }],
        endTime: [{ required: true, message: "请选择结束时间", trigger: "change" }]
      }
    };
  },
  mounted() {
    this.getAllNotice();
  },
  methods: {
    closeDialog() {
      this.dialogProps.visible = false;
    },
    pickerOptionsStart(date) {
      return {
        disabledDate(time) {
          if (date !== "") {
            return time.getTime() > new Date(date).getTime();
          }
        }
      };
    },
    pickerOptionsEnd(date) {
      return {
        disabledDate(time) {
          if (date !== "") {
            return time.getTime() < new Date(date).getTime();
          }
        }
      };
    },
    sureCommit() {
      this.$refs.editFrom.validate(valid => {
        if (valid) {
          this.btnLoading = true;
          updateQuestion(this.editFrom)
            .then(res => {
              this.btnLoading = false;
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.getAllNotice();
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.$refs.editFrom.clearValidate();
      this.editFrom = {
        endTime: "",
        id: "",
        startTime: ""
      };
    },
    searchResule() {
      this.searchFrom.pageNo = 1;
      this.getAllNotice();
    },
    reviewTemplate(row) {
      if (row.questionType === "1") {
        this.$refs.applicationForm.dialogVisible = true;
      } else {
        this.dialogProps = {
          visible: true,
          factionType: row.id,
          title: "满意度调查问卷"
        };
      }
    },
    edit(val) {
      this.editFrom = {
        endTime: val.endTime,
        id: val.id,
        startTime: val.startTime
      };
      this.dialogVisible = true;
    },
    downloadFn(val) {
      postRequestDown(`/questionnaire/download/${val.id}`).then(res => {
        const blob = new Blob([res], { type: res.type });
        const aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = URL.createObjectURL(blob);
        aLink.download = val.title + ".docx";
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
      })
    },
    getQRcode(row) {
      let loadingInstance = Loading.service({ text: "下载中,请稍后...", target: "body" });
      createQrCode(row.id).then(res => {
        let base64Str = "data:image/jpeg;base64," + res;
        let aLink = document.createElement("a");
        aLink.style.display = "none";
        aLink.href = base64Str;
        aLink.download = row.title + "二维码.jpg";
        document.body.appendChild(aLink);
        aLink.click();
        document.body.removeChild(aLink);
        loadingInstance.close();
      }).catch(() => {
        loadingInstance.close();
      });
    },
    getAllNotice() {
      this.disabledSearch = true;
      this.loading = true;
      queryQuestionList(this.searchFrom)
        .then(res => {
          this.total = res.count;
          this.loading = false;
          this.disabledSearch = false;
          this.tableData = res.data;
        })
        .catch(err => {
          this.loading = false;
          this.disabledSearch = false;
        });
    },
    handleSizeChange(val) {
      this.searchFrom.pageSize = val;
      this.getAllNotice();
    },
    handleCurrentChange(val) {
      this.searchFrom.pageNo = val;
      this.getAllNotice();
    }
  }
};
</script>

<style lang="scss" scoped>
.question-template-page {
  background: transparent;
}

.searchFrom > .el-form-item {
  margin-right: 30px !important;
}

.Selectnum {
  padding: 6px 30px;
  border: 1px solid #bfcbf4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
